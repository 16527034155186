import React from 'react'
import {Typography,Box,} from '@mui/material'
const sectionHeader = ({header,bordColor = '#f2ebd7', color ='#74060b'}) => {
  return (
    <Box 
    // width='auto'
    // margin='0 auto'
   
    sx={{
        marginTop:'50px',
        marginBottom:'20px',
        // margin: '50px auto 0px auto'   ,   
      // padding:"0 50px 0 50px",
      fontFamily:"Rubik, sans-serif",
      width:'90%'
    
    }}
    
    >
    <Typography variant='h1'
      textTransform='uppercase'
      borderBottom={`3px solid ${bordColor}`}
      color={color}
    
        textAlign='left'
        fontFamily={'Pt Sans, sans-serif'}
        letterSpacing='1.2px'
       
        sx={{
            fontSize:{
                xs: '25px',
                sm: '40px',
                md: '50px',
            },
      // border:'1px solid blue',
      marginLeft: '20px'

        }}
    >
        {header}
    </Typography>
 
    
    </Box>
    
    
  )
}

export default sectionHeader