import sliderPic from '../assets/images/sliderImg1.png'
import sliderPic2 from '../assets/images/sliderImg2.png'
import sliderPic3 from '../assets/images/sliderImg3.png'
import sliderPic4 from '../assets/images/sliderImg4.png'


export  const sliderInfo =

[

    {
    "id": 1,
    "image" : sliderPic,
    "title" : "Slider Image 1"
    
    },
    {
        "id": 2,
        "image" : sliderPic2,
        "title" : "Slider Image 2"
        
        },
    {
    "id": 3,
    "image" : sliderPic3,
    "title" : "Slider Image 3"
    
    },
    {
        "id": 4,
        "image" : sliderPic4,
        "title" : "Slider Image 4"
        
        }
    
    ]
