import React, {useState} from 'react'
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    IconButton
  } from "@mui/material";
  import MenuIcon from '@mui/icons-material/Menu';
  import CloseIcon from '@mui/icons-material/Close';
  import { Link } from "react-router-dom";
  import { navItems } from '../utils/resuablesJS';
  import { isHome } from '../utils/resuablesJS';
  // import  {styled} from "@mui/material"

  function DrawerComponent() {
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
      <>
        <Drawer open={openDrawer}  sx={{position:'relative'}} >

        <IconButton onClick={() => setOpenDrawer(false)}   
        sx={{backgroundColor:"#f2ebd7", borderRadius:"0", position:'fixed', top:{sm:"2%", xs: "3.5%"}, left:{sm:"92%", xs: "82%"}, transform:" rotate(45deg) ",  "&:hover":{backgroundColor:"#f2ebd7"} }}>
        <CloseIcon sx={{color: "#74060b", transform:" rotate(45deg) "}} />
      </IconButton>
      <List sx={{backgroundColor:'#f2ebd7', height: '100%', }}>
    
      {navItems.map((navItem) => (

        <ListItem key={navItem}  onClick={() => setOpenDrawer(false)}>
        <ListItemText>
          <Link   to={isHome(`${navItem}`)} className='mobile-Link' >{navItem}</Link>
        </ListItemText>
        </ListItem>
      ))}
    </List>
        </Drawer>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon sx={{color: "#f2ebd7"}} />
      </IconButton>
        </>
    );
  }
  export default DrawerComponent;
  