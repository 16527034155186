import {Box} from  "@mui/material"
import {styled} from "@mui/system"


const FlexBetween = styled(Box)({
    display: "flex",
    flexDirection: "space-between",
    justifyContent: "center",
    flexGrow: '1'
    // border: "1px solid red"
  
})

export default FlexBetween