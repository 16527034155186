import React, {useContext} from 'react'
import { Box, Typography} from '@mui/material'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import OfficerCard from './OfficerCard';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import RightArrowIcon from '../assets/icons/right-arrow.png';
import LeftArrowIcon from '../assets/icons/left-arrow.png';




const LeftArrow = () => {
  const { scrollPrev } = useContext(VisibilityContext);

  return (
    <Typography onClick={() => scrollPrev()} className="right-arrow">
      <img src={LeftArrowIcon} alt="right-arrow" />
    </Typography>
  );
};

const RightArrow = () => {
  const { scrollNext } = useContext(VisibilityContext);

  return (
    <Typography onClick={() => scrollNext()} className="left-arrow">
      <img src={RightArrowIcon} alt="right-arrow" />
    </Typography>
  );
};

const HorizonatalScrollBar = ({data}) => {
  return (
    
        <ScrollMenu 
        LeftArrow={LeftArrow} RightArrow={RightArrow}
        >
    
            {data.map((item)=>(
              
            <Box key={item.id} 
            m ='0 40px'
            py ='25px'
            sx={{
              // border: '3px solid purple',
              // width:'100%'
              // Height: '250px',
            }}
            >  
            <OfficerCard item={item} />
            </Box>   
           ))}
        </ScrollMenu>
  )
}

export default HorizonatalScrollBar