import React from 'react';
import { Carousel } from 'react-carousel-minimal'
import { sliderInfo } from '../utils/sliderJS'
import {Box,Typography } from '@mui/material'

const Carousel2 = () => {
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#74060b'
  }
  const showNavBarStyle = {
    fontSize: '16px',
    
    backgroundColor: '#74060b'
  }
  return (
    <div style={{ textAlign: "center", 
    margin:'40px 0', position : 'relative'
    }}>

      <Carousel
        data={sliderInfo}
        time={4000}
        width="1550px"
        height="500px"
        captionStyle={captionStyle}
        radius="2px"
        slideNumberStyle={slideNumberStyle}
        captionPosition="bottom"
        automatic={true}
        dots={true}
        pauseIconColor="#74060b"
        pauseIconSize="40px"
        slideBackgroundColor="black"
        slideImageFit="fill"
        showNavBarStyle={showNavBarStyle}
        style={{
          textAlign: "center",
          maxWidth: "1550px",
          maxHeight: "500px",
          margin: "40px auto",
         
        }}
      />
      <Box
     sx={{
    
       width:'100%',
       position:'absolute',
      //  top:'350px',
       top: {
           xs:"65%",
           sm:"60%",
      
       },
       zIndex:'2',
     
     }}
     ><Typography
     sx={{
      fontfamily:'Rubik, sans-serif',
      textAlign: 'left',
      fontWeight:'bold',
      color:'#74060b',
    
      textShadow: '#f2ebd7 1px 0 5px',
      padding: ' 0 5px',
    
      fontSize: {
             xs: '5vw',
             md:'60px'
      }
    }}
    >
     We Are the
     
    </Typography>
    <Typography
     sx={{
      fontfamily:'Rubik, sans-serif',
      textAlign: 'left',
      fontWeight:'bold',
      color:'#74060b',
   
      textShadow: '#f2ebd7 1px 0 5px',
      padding: ' 0 5px',
    
      fontSize: {
             xs: '7vw',
             md:'50px',
             lg:'60px',
      }
    }}

    
    > Springfield Alumni Nupes</Typography>
   </Box>

    </div>
  )
}

export default Carousel2