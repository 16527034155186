import React from 'react'
import { Box, Typography, Stack, Grid} from '@mui/material'

import SectionHeader from '../Components/SectionHeader'
import ogNupes from '../assets/images/ogNupes.png'
import ntn8 from '../assets/images/ntn8.jpg'
import sliderImg2 from '../assets/images/slider-img2.JPEG'
import { styled } from '@mui/system';
import BackToTopBtn from '../Components/BackToTopBtn'
import ImageBlurhash from '../Components/ImageBlurhashCH'
const History = ({isMobile , isLarge}) => {


  const HistoryTypography = styled(Typography)({
    fontfamily:'Rubik, sans-serif',
    fontSize: "1.2rem",
    lineHeight: "2.75rem",
    padding: "0 3rem",
  })

  return (
<>
<Grid container 
id="historyTop"
 bgcolor={"white"}
 sx={{padding: "150px 0", }}>

  <SectionHeader header ={'Chapter History'} />

          <Grid item 
          sx={{margin: '25px 0'}}>
          <Stack
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection:isLarge ? 'column': 'row ',
          }}>
          <Box>

          <ImageBlurhash src ={ogNupes}
          alt ={ogNupes}
          isLarge ={isLarge} isMobile={isMobile} />
          {/* <StyledImg src={ogNupes} alt={ogNupes}  /> */}


          </Box>
          <HistoryTypography
           variant='body1' >
            The <span className='emphasized-Text'><strong> Springfield Alumni</strong></span> Chapter of <span className='emphasized-Text'><strong> Kappa Alpha Psi </strong></span> Fraternity, Inc was chartered on <span className='emphasized-Text'><strong>June 23, 1989 </strong></span>. Springfield Alumni services Western Massachusetts and the greater Springfield region. Springfield Alumni has been an active chapter in the <span className='emphasized-Text'><strong>Northeastern Province </strong></span> of Kappa Alpha Psi Fraternity since the charter date. Springfield Alumni members are productive citizens of Western Massachusetts. Our members are motivated to model the fundamental purpose of our fraternity, which is <span className='emphasized-Text'> <strong>Achievement</strong></span>.
          </HistoryTypography>
          </Stack>  
        </Grid>

        <Grid item 
          sx={{margin: '25px 0'}}>
          <Stack
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection:isLarge ? 'column': 'row ',
          }}>
          <Box>
          {/* <StyledImg src={ntn8} alt={ntn8}  /> */}

          <ImageBlurhash src ={ntn8} 
          alt={ntn8}
          isLarge ={isLarge} isMobile={isMobile}
          />

          </Box>
          <HistoryTypography
           variant='body1' >
             <span className='emphasized-Text'><strong> Springfield Alumni 
           </strong></span> proudly participates in youth mentorship as well as social action geared towards uplifting our community. Springfield Alumni monitors the collegiate undergraduate chapter, 
           <span className='emphasized-Text'><strong>  Nu Tau (University of Massachusetts at Amherst, American International College, Westfield State University, and Amherst College)
           </strong></span>  to ensure their events and activities meet the fraternity standards.
          </HistoryTypography>
          </Stack>  
        </Grid>

        <Grid item 
          sx={{margin: '25px 0'}}>
          <Stack
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection:isLarge ? 'column': 'row ',
          }}>
          <Box>
          {/* <StyledImg src={sliderImg2} alt={sliderImg2}  /> */}

          <ImageBlurhash src ={sliderImg2} 
          alt={sliderImg2}
          isLarge ={isLarge} isMobile={isMobile}
          />
          </Box>
          <HistoryTypography
           variant='body1' >
             <span className='emphasized-Text'><strong> Springfield Alumni 
           </strong></span> is willing to work with any <span className='emphasized-Text'><strong> community- based organization(s)
           </strong></span> that value our <span className='emphasized-Text'><strong> principles
           </strong></span>. Kappa Alpha Psi Fraternity, Inc. is a non- profit organization. Any funds raised by the  Springfield Alumni chapter are to be used in programming or operational expenses.
          </HistoryTypography>
          </Stack>  
        </Grid>
        <BackToTopBtn />
   </Grid>
</>
  )
}

export default History