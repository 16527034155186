import React from 'react'
import { Box, Typography, Grid,Stack} from '@mui/material'
import {styled} from "@mui/system"
import SectionHeader from '../Components/SectionHeader'
import FlexBetween from '../Components/FlexBetween'
import UmassNupes from '../assets/images/umassNupes.png'
import Founders from '../assets/images/founders.jpeg'
import BackToTopBtn from '../Components/BackToTopBtn'
import ImgBlurAbout from '../Components/imageBlurAbout'
import ImgBlurAbout2 from '../Components/imgBlurABout2'
const About = ({ isMobile2}) => {

    const StyledTypography = styled (Typography)({
        fontSize: '1.2rem',
        lineHeight: "2.75rem",
        padding:  isMobile2 ? "0 1.5rem": "0 3rem",
        marginTop: "1rem"
      
    })
  return (
  <>
  <Grid container 
 
bgcolor={"white"}
sx={{ padding: "150px 0",}}>

       <SectionHeader header ={'About Us'} />

<Grid item
    //  xs={8}
    sx={{
        display: 'flex',
          justifyContent: 'center',
          width:'100%',
    }}>
        <Stack >
        <ImgBlurAbout src={UmassNupes} alt='Umass Nupes at Umass Homecoming fall 11'
        isMobile2={isMobile2} 
        />

          {/* <img  src={UmassNupes} alt={UmassNupes} 
              style={{
                width: isMobile2 ? '100%':'550px',
                loading:'lazy',
                alignSelf: isMobile2 ? 'flex-start' : 'center',
                objectFit: 'contain',
            boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',

              }}
          
          /> */}



        </Stack>
        

    </Grid> 




    <Grid item>
          <Box>
      <StyledTypography variant='body1'
      > 
     Made of members of its undergrad chapter Nu Tau, Theta Iota, any more more prestigious
       chapters. <span className='emphasized-Text'>
           <strong>
           Springfield Alumni 
           </strong>
       </span> has become a second home for many brothers, who have greatly contributed to making the community and 
       the chapter is better. We pride ourselves on three things, <span className='emphasized-Text'>
           <strong>
           community
           </strong>
       </span>,  <span className='emphasized-Text'>
           <strong>
           brotherhood
           </strong>
       </span>  
         , and <span className='emphasized-Text'>
           <strong>
            achievement
           </strong>
       </span>. Brothers of this prestigious chapter have gone on to work in the community, by opening a local brewery, running the local Boys and girls club, heading perfoming arts shows and much more. 

      </StyledTypography>

      </Box>

    </Grid>
    <Grid item
        sx={{
            paddingRight:'15px',
            margin: '0 auto'
        }}
    >

  <ImgBlurAbout2  src={Founders} alt='Founders'
        isMobile2={isMobile2} />    
{/*         
 <img src= {require('../assets/images/founders.jpeg')}
    
    alt='Founders'
    style={{
      boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
      width:'90%',
    padding:'0',
      margin:"1.563rem", 
      loading:'lazy',
     }} /> */}
    </Grid>
    <Grid item>
      
    <FlexBetween>
      <StyledTypography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          marginTop: "1rem"
        }}
      >   <span className='emphasized-Text'>
           <strong>
           The chapter 
           </strong>
       </span>   offers a variety of programs and initiatives that aim to uplift the community, including youth mentoring programs, college scholarship opportunities, and community service projects. In addition, the chapter hosts numerous events throughout the year, including social gatherings, fundraisers, and networking opportunities. Through these events and initiatives, <span className='emphasized-Text'>
           <strong>
           the Springfield Alumni Chapter of
           </strong>
       </span>    Kappa Alpha Psi has established itself as a vital organization that plays an important role in the growth and development of the local community.
       </StyledTypography>
</FlexBetween>
    </Grid>
    <BackToTopBtn />
  </Grid>
  </>
  )
}

export default About