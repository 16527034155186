import React from 'react'
import { Typography, Box, Stack, Accordion,AccordionSummary,AccordionDetails} from '@mui/material'
import SectionHeader from '../Components/SectionHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { serviceData } from '../utils/resuablesJS';
import BackToTopBtn from '../Components/BackToTopBtn'
import ImgBlurService from '../Components/imgBlurService';
const Service = ({ isMobile2}) => {
  return (
    <Box
    sx={{
     marginTop: "90px",
     backgroundColor:'white', 
     height:"100%",
     paddingTop: "50px"
  
  }}
    >
     <SectionHeader header ={'Service'} />
   
   <Box
   display="flex"
   flexDirection= "column"
   justifyContent="space-between"
     minHeight = "900px"
     sx={{
       paddingBottom:'5.125rem'
       
      }} >
    <Typography
    variant='body1'
    className='service-info-div-0'
    sx={{
      fontSize:  isMobile2 ? ".95rem" : '1.2rem',
      lineHeight: "2.75rem",
      padding:  isMobile2 ? "0 1.5rem": "0 3rem",
      marginTop: "1rem",
      textAlign: "justify",
    }}
    >
The Springfield alumni chapter is committed to servicing our community and fraternity but most importantly we strive to make all those around us <span className='emphasized-Text '><strong> ACHIEVE</strong></span>. We seek to not only <span className='emphasized-Text upperText'><strong>promote the Spiritual, Social, Intellectual, and Moral Welfare of members</strong></span>, but as well to those around us. When the call of service comes the men of the Springfield Alumni chapter of Kappa Alpha Psi are ready to answer. To find out more of the initiatives we are involed in check out the cards below.
    </Typography>
<Stack
      sx={{
       display: "flex",
       justifyContent: "center",
       flexDirection: "column",
       alignItems: "center",
      }}     
     >
   {serviceData.map((data)=>(
     <Accordion 
     key={data.id}
      sx={{
        width: "95%",
        minHeight: 150, //ugly but works
        height: "100%",
        boxShadow: '0px 2px 1px -1px rgb(242, 235, 215), 0px 1px 1px 0px rgb(242, 235, 215), 0px 1px 3px 0px rgb(242, 235, 215)',
        '&::before': {
         backgroundColor: "#74060b",
        }
      }}
     >
     <AccordionSummary
     sx={{
      cursor: "pointer",
     }}
       expandIcon={<ExpandMoreIcon 
        sx={{
          color: "#74060b",
        }} />}
       aria-controls="panel1a-content"
       id="panel1a-header"
     >
       <Typography
        sx={{
          fontSize: "1.2 rem",
          textAlign: "left",
          lineHeight: "1.75rem",
          padding: "0 1.5rem",
          marginTop: ".5rem",
          color: "#74060b",
          fontweight: "bold",
          textTransform: "uppercase",
        }}
       
       >{data.title} </Typography>
     </AccordionSummary>
     <AccordionDetails
      sx={{ border: "1px solid #f2ebd7",}}>
     <Stack >
       <div className='service-Img-Box'>

       <ImgBlurService 
        src={data.imgUrl}
        alt={data.title}
       />
       {/* <img 
       src={data.imgUrl}
       alt={data.title}
       style={{
        width: '100%',
        height: '100%',
        loading:'lazy',
      }}/> */}
       </div>
       <div dangerouslySetInnerHTML={{__html: data.info}} className='service-info-div'></div>
     </Stack>
     </AccordionDetails>
   </Accordion>
   ))}
</Stack>
    </Box>
    <BackToTopBtn />
    </Box>
  )
}

export default Service