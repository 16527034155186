import React from 'react'
import {Typography,Stack, Button,TextField, Grid, Box } from '@mui/material'
import {  styled } from '@mui/material/styles';
import SectionHeader from '../Components/SectionHeader'
import emailjs from '@emailjs/browser';
import BackToTopBtn from '../Components/BackToTopBtn'



const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#74060b',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#74060b',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F2EBD7',
    },
    '&:hover fieldset': {
      borderColor: '#74060b',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#74060b',
    },
  },
});



const Contact = ({isMobile}) => {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5s033de', 'template_SA_nupe', e.target, 'QK7UGelscHoq0QYXJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset() 
  };
  return (
    <Box
    sx={{
     marginTop: "90px",
     backgroundColor:'white', 
     height:"100%",
     paddingTop: "50px", 
  }}>
       <SectionHeader header ={'Contact Us'} />

     < Grid container
        direction= "column"
        justifyContent="space-around" // just this the flex box goes to column  
        alignItems="center"
        sx={{
          paddingBottom:'5.125rem',
      }}
      >
         <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          margin: "1rem 0"
        }}>  Have any concerns? Feel free to reach out  to the  <span className='emphasized-Text'><strong>
           Springfield Alumni Nupes</strong></span>. We are here to help answer any questions regarding our chapter, interest in the fraternity or any future collaborations. We look forward to hearing from you. <span className='emphasized-Text'><strong>
           YO!</strong></span>
      </Typography>

      <Box
           component="form" 
           onSubmit={sendEmail} 
           noValidate 
           sx={{ mt: 1,
            width: {xs: '100%', md: '80%'},
            padding:'.75rem'
           }} >
   
               <Stack
               direction='row'
               gap={1}
               sx={{
                 flexDirection: { 
                  xs: 'column',
                  md: 'row'
                }
      
               }}
          
                >
   
               <CssTextField
                 margin="normal"
                 required
                 fullWidth
                 id="firstName"
                 label="First Name"
                 name="firstName"
                 autoFocus
                 className='contact-TextField'
               />
   
   
               <CssTextField
                 margin="normal"
                 required
                 fullWidth
                 id="lastName"
                 label="Last Name"
                 name="lastName"
                 autoFocus
                 className='contact-TextField'
   
               />
               </Stack>
   
                 <CssTextField
                 margin="normal"
                 required
                 fullWidth
                 id="email"
                 label="Email Address"
                 name="email"
                 autoComplete="email"
                 autoFocus
                 className='contact-TextField'
               />
   
               <CssTextField
                 margin="normal"
                 required
                 fullWidth
                 multiline
                 rows={6}
                 maxRows={6}
                 name="message"
                 label="Message"
                 type="text"
                 id="password"
                 sx={{
                     color: '#74060b',
              
                 }}
                 className='contact-TextField  contact-TextField-message'
               />
   
            <Button
                 type="submit"
                 fullWidth
              
                 variant="contained"
                   style={{ background: '#74060b', color: '#F2EBD7',padding: '20px'}}
                 sx={{ mt: 3, mb: 2, fontSize:{ xs:'18px' ,md:'23px'}
                
                }}
               >
                 Send
               </Button>
          
   {/* https://github.com/mui/material-ui/blob/v5.10.5/docs/data/material/getting-started/templates/sign-in/SignIn.js  to addd in res */}
           </Box>

          </Grid>
          <BackToTopBtn />
    </Box>
 
  )
}

export default Contact