import notatbleNupes from "../assets/images/NotableKapps.jpg"


import ntn from '../assets/images/ntn.jpg'
import ntn2 from '../assets/images/ntn2.jpg'
import ntn3 from '../assets/images/ntn3.jpg'
import ntn4 from '../assets/images/ntn4.jpg'
import ntn5 from '../assets/images/ntn5.jpg'
import ntn6 from '../assets/images/ntn6.jpg'
import ntn7 from '../assets/images/ntn7.jpg'



import ntn8 from '../assets/images/ntn11.JPEG'
import ntn9 from '../assets/images/ntn9.jpg'
import ntn10 from '../assets/images/ntn10.JPEG'


import ntn11 from '../assets/images/slider-img3.JPEG'
export const imageInfoData = [
notatbleNupes,ntn,ntn2,ntn3,ntn5,ntn6,ntn8,ntn4,ntn11,ntn7,ntn9,ntn10
]