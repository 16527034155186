import React from 'react'
import{Typography, Card,CardMedia,CardContent,} from '@mui/material'


const OfficerCard = ({item}) => {
  return (
    <Card sx={{ 
      width:275,
      
      // width: 207,
      // minHeight: 450,
    // height: 450
    // minWidth:207,
    // minHeight:600,
    
    }}>
        <CardMedia  
        component='img'
        height='200px'

        image={item.headshots}
         style ={
             {
                objectPosition: 'center top',
             }
         }
        />
        <CardContent
         style ={{
              background:'#f2ebd7',
              height:'100%',
            }}

        >
        <Typography gutterBottom variant="h5" component="div" color="#74060b"
             style ={{
            
                    fontFamily:'PT Sans , sans-serif',
                    fontWeight: '601',
                }}
        > 
         {item.name}
        </Typography>
        <Typography variant="body2" color="#74060b" 
             style ={{
            fontFamily:'Roboto',
            lineHeight:'1.8rem ',
            height:'250px',
            overflowY: 'scroll'

        }}
        >
         {item.officerInfo}

         {/* (whole bio text will go here) */}
        </Typography>
        </CardContent>
    </Card>
  )
}

export default OfficerCard