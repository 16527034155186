import React, {useEffect,useState} from 'react'
import { Blurhash } from 'react-blurhash'
const ImageBlurhashCH = ({src, isLarge, isMobile, alt}) => {
    const [imageLoaded, setImageLoaded] = useState(false)


    useEffect(()=>{
        const img  = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src
    },[src] )
  return (
      <>


        <div style ={{display: imageLoaded ? 'none' : 'inline'}}>
          <Blurhash
          hash="LVQS;%of_Nj@nij[Rkay?bfQMxfQ"
          width={ isMobile ? '250px':'450px'}
          height={ isMobile ? '250px':'450px'}
          resolutionX={32}
          resolutionY={32}
          punch={1}/>
          </div>
    <img 
    src={src}
    alt={alt}
    style ={{display: !imageLoaded ? 'none' : 'inline',
    boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
    maxWidth: isMobile ? '250px':'450px',
    objectFit: 'contain',
    marginBottom: isLarge ? '30px' : '0',
    marginLeft: isLarge ? '0px' : '20px'


}}
    />
    </>
  )
}

export default ImageBlurhashCH