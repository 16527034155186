import React from 'react'
import {
    AppBar,
    Toolbar,
    // Stack,
    Box,
    // Typography,
    useTheme,
    useMediaQuery,
  } from "@mui/material"
  import { Link } from "react-router-dom";
  import  {styled} from "@mui/material"
  import ChapLogoRev from '../assets/images/chapLogoRev.png'

  import DrawerComponent from './Drawer';
  import { navItems } from '../utils/resuablesJS';
  import { isHome } from '../utils/resuablesJS';


  const StyledDiv = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(10),
    display: "flex",
   
    "> .Link":{
      textDecoration: "none",
      color: "#f2ebd7",
      fontSize: "1.25rem",
      marginLeft: theme.spacing(9.5),
      fontWeight: "bold",
      "&:hover": {
        color: "#f3ecd8ad",
        fontSize: "1.30rem",
        borderBottom: "1px solid #f2ebd7",
        transition: ".3s all"
      },
    }
  }));

const Navbar = () => {
    // const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
     <AppBar 
     position="fixed"
     sx={{
       backgroundColor:'#74060b',
       height:'110px',
    }}
    >
    
      <Toolbar>

        <Box  
        sx={{
          flexGrow: "1",
        
        }}
          
          >
           <Link to="/" className='Link' >
          <img  style={{
             width: "70px",
             height: "70px",
             border: '2px solid #f2ebd7', 
            borderRadius: '50%',
            margin: '10px 0 8px 0',
            className:'Logo-mobile'
          }} 
          src={ChapLogoRev}
          alt='SA logo Alternate'
          />
          </Link>
        </Box >
        {isMobile ? (
           <DrawerComponent />
           ) : (
          <StyledDiv>
        
        {navItems.map((navItem) => (
       <Link key={navItem} to={isHome(`${navItem}`)} className='Link' >
       {navItem}
     </Link>
      ))}
          </StyledDiv>
        )}
        </Toolbar>
    </AppBar>
  )
}

export default Navbar