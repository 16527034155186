import React,{useState, useEffect} from 'react'
import{Paper,Typography,Modal,Card,CardMedia,CardContent,IconButton} from '@mui/material'
import { Box} from '@mui/system';

import CloseIcon from '@mui/icons-material/Close';


const Officer = ({position, index, name,officerInfo, officerHeadshot, isMobile}) => {
  
    const [open, setOpen] = useState(false);

const handleOpen = () => setOpen(true);

const handleClose = () => setOpen(!open);
const isMediumSize =  900

const [winDimension, detectWidth] = useState({
    winWidth: window.innerWidth
  })
  


  const dectectSize = () =>{
    detectWidth({
       winWidth: window.innerWidth
    })
  }
  useEffect(()=>{
  
    if(winDimension.winWidth < isMediumSize) setOpen(false)
   
      window.addEventListener('resize', dectectSize)
  
      return () => {
          window.removeEventListener('resize', dectectSize)
  
      }
  },[winDimension])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#74060b',
    boxShadow: 24,
    p: 4,
    border:'1px solid #F2EBD7'
    
  };
  
    return (
   <>
       <Box onClick={handleOpen}
       display='flex'
       flexDirection='column '
       alignItems='center'
       margin={4}
       
        sx={{
            cursor: 'pointer',
        }}
       
       >
        <Paper
        sx={{
        background: `url(${officerHeadshot}) no-repeat top center / 145px 145px #F2EBD7`,
        borderRadius: '50%',
        border: '3px solid #f2ebd7',
        width:'140px',
        height:'140px',
        marginBottom: '10px',
        }}
        
        >

        </Paper>
        <Typography variant='subtitle1'
            sx={{
                fontSize:{    
                            md:'20px',
                            xs:'16px'
                        },
                        minWidth:'200px',
                        textAlign:'center',
                        borderRadius: '5px',
                        border: '3px solid #f2ebd7',
                        padding: '10px',
                        color:'#f2ebd7',
            }}
        >
        {position}
      </Typography>
            
       </Box>
        <Modal
         open={open}
        //  onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
        >
 <Card 
                id={index}
                sx={style}
            >
              <IconButton
              onClick={handleClose}
                style={{
                  color: '#F2EBD7',
                  position: 'absolute',
                  top: '0',
                  right: '0%',
                }}
              >
                <CloseIcon />
              </IconButton>
        
             
         <CardMedia  
             component='img'
                image={officerHeadshot}
             style ={
            {
            objectPosition: 'center top',
             }
            }
             /> 
               <CardContent
         style ={{
              background:'#f2ebd7'
            }}
        >
        <Typography gutterBottom variant="h5" component="div" color="#74060b"
             style ={{
                  
                    fontFamily:'PT Sans , sans-serif',
                    fontWeight: '600',
                }}
        > 
       {name}

        </Typography>
        <Typography gutterBottom variant="h6" component="div" color="#74060b"
             style ={{
               
                    fontFamily:'PT Sans , sans-serif',
                    fontWeight: '600',
                }}
        > 
        {position}

        </Typography>

        <Typography variant="body2" color="#74060b" 
             style ={{
            fontFamily:'Roboto',
            lineHeight:'1.2rem '
        }}
        >
         {officerInfo}
        </Typography>
        </CardContent>

            </Card>

        </Modal>
   
   </>
  )
}

export default Officer