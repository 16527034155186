import React, {useEffect,useState} from 'react'
import { Blurhash } from 'react-blurhash'
const ImgBlurAbout = ({src,srcSet,alt, isMobile2}) => {
    const [imageLoaded, setImageLoaded] = useState(false)


    useEffect(()=>{
        const img  = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src
    },[src] )
  return (
      <>

        <div style ={{display: imageLoaded ? 'none' : 'inline'}}>
          <Blurhash
          hash="LXMGI?9Fu3%1%~n#r[NZy?XSQ-xu"
          width={ isMobile2 ? '100%':'550px'}
          height={ 'auto'}

          resolutionX={32}
          resolutionY={32}
          punch={1}/>
          </div>
    <img 
    src={src}
    alt={alt}
    srcSet={srcSet}
    style ={{display: !imageLoaded ? 'none' : 'inline',
     width:isMobile2 ? '100%':'550px',
    height: '100%',  objectFit: 'contain',
    boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
    


}}
    />
    </>
  )
}

export default ImgBlurAbout