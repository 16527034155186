
import kappaLeagueLogo from '../assets/images/kl-Logo.png'  
// import kappaLeagueBanner from '../assets/images/klBanner.png'
import Achievement from '../assets/images/Achievement.png'
import guideRightLogo from '../assets/images/guideRight.webp'
import WhitePartyFlyer from '../assets/images/Spfld-Nupes-White-Party.jpeg'
import  JoeOfficerCard from '../assets/images/polemarch.png'
import XavierOfficerCard from '../assets/images/XCartyOfficerCard-1.png'
import DominicOfficerCard from '../assets/images/DSmithOfficerCard-1.png'
import BrianOfficerCard from '../assets/images/BrianMOfficerCard-1.png'
import VanOfficerCard from '../assets/images/VanJOfficerCard-1.png'
import KeshawnOfficerCard from '../assets/images/KDODDSOfficerCard-1.png'
import JeffOfficerCard from '../assets/images/JeffOfficerCard-1.png'
import reclamation from '../assets/images/FRATERN.jpg'


export  const navItems = [
    "Home",
    "About",
   "Officers",
   "Service",
   "Events",
   "Contact",
]

export  const mobileNavItems = [
    "Home",
    "About",
   "Officers",
   "Service",
   "Events",
   "Contact",
   "Links",
]

export  const officerPositions = [
    "Polmarch",
   "Vice Polemarch",
   "Keeper of Records",
   "Exchequer",
   " Historian",
   "Strategus ",
   "Lieutenant Strategus",
   " Grand Board  Mem 1",
   " Grand Board  Mem 2",
   "  Grand Board  Mem 3",
]

export const
serviceData = [
    {
        id: 1,
        title: "Guide Right",
        imgUrl: `${guideRightLogo}`,
        info:" The purposes of the Guide Right Service Program are to place the training, experience, and friendly interest of successful men, at the disposal of youth needing inspiration and counsel regarding their choice of a life's career, while the community is made aware of the problems that may be encountered as these youth seek to realize lives of usefulness.",
    },
    {
        id: 2,
        title: "to Inspire",
        imgUrl:`${Achievement}`,
        info:"All Kappa men hear the call for social action. We strive <span className='emphasized-Text'> <strong> to inspire service in the public interest</strong></span>. Our Main Goal is to make our community a better place for people to  live freely and provide a positive role model for many young men and women. By leaving a lasting impression  we hope to make a positive impact on the lives of others. Check out our <a  href='Events'>Events</a> section to learn more!",
    },
    {
        id: 3,
        title: "Kappa League",
        imgUrl: `${kappaLeagueLogo}`,
        info:" The Kappa Youth Leadership & Development League is a program designed to aid young men grow and develop their leadership talents in <span className='emphasized-Text'><strong>every field of human endeavor</strong></span>. This national initiative commonly referred to as “Kappa League,” is a program for the educational, occupational and social guidance of male students in grades 6th-12th.",
    },
    {
        id: 4,
        title: "Reclamation",
        imgUrl:`${reclamation}`,
        info:"The Springfield Alumni brothers have always made it a point to <span className='emphasized-Text'><strong> to Inspire</strong></span>. What other better way <span className='emphasized-Text'><strong> to Inspire</strong></span>, by bring the all good brothers back home to <span className='emphasized-Text'><strong>KAPPA</strong></span>. We are invested in bringing brothers back to the bond not only on a finaicial level, but also back into the warm embarce of brotherhood.",
    },
]

export  const officerPositionsCardInfo = [
    {
        name: "Joseph Paige",
        id:1,
        headshots: JoeOfficerCard ,
        position: "Polmarch",
        officerInfo: "The Polemarch of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. is responsible for providing effective leadership and oversight to the chapter. This role involves coordinating and executing the chapter's activities, maintaining strong relationships with members, and upholding the values and traditions of Kappa Alpha Psi.",
    },  {
        name: "Dominic Smith",
        id:2,
        headshots: DominicOfficerCard ,
        position: "Vice Polemarch",
        officerInfo: "The Vice Polemarch of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. plays a vital role in supporting the Polemarch and the overall functioning of the chapter. This role involves assisting in the coordination and execution of chapter activities, maintaining effective communication with members, and upholding the values and traditions of Kappa Alpha Psi.",
    },
    {
        name: "Xavier Carty",
        id:3,
        headshots: XavierOfficerCard ,
        position: "Keeper of Records",
        officerInfo: "The Keeper of Records of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. holds a crucial role in maintaining accurate and organized records of chapter activities and membership. This position involves documenting and preserving important chapter documents, including meeting minutes, financial records, and membership records.",
    },  {
        name: "Brian McCrae",
        id:4,
        headshots: BrianOfficerCard ,
        position: "Exchequer",
        officerInfo: "The Exchequer of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. holds the responsibility of managing the chapter's financial affairs. This role involves maintaining accurate financial records, overseeing budgeting and financial planning, and ensuring compliance with financial policies and regulations. ",
    },
    {
        name: "Joeseph Paige",
        id:5,
        headshots: JoeOfficerCard ,
        position: "Historian",
        officerInfo: "The Historian of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. serves as the custodian of the chapter's history and traditions. This role involves documenting and preserving the chapter's historical records, including photographs, artifacts, and written accounts. ",
    },  
    
    // {
    //     name: "Joe Smith",
    //     id:6,
    //     headshots: "photos will be ref here" ,
    //     position: "Strategus",
    //     officerInfo: "Lorem Ipsum  is Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum Ipsum",
    // },
    // {
    //     name: "Joe Smith",
    //     id:7,
    //     headshots: "photos will be ref here" ,
    //     position: "Lieutenant Strategus",
    //     officerInfo: "Lorem Ipsum  is Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum Ipsum",
    // }, 
     {
        name: "Keshawn Dodds",
        id:6,
        headshots: KeshawnOfficerCard ,
        position: "Director of Guide Right",
        officerInfo: "The Director of Guide Right of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. is responsible for leading and implementing the chapter's Guide Right program. This role involves coordinating and overseeing mentoring, educational, and community service initiatives that aim to positively impact the lives of young men in the community.",
    },
    {
        name: "V. Van Johnson",
        id:7,
        headshots: VanOfficerCard ,
        position: "Director of Reclamation",
        officerInfo: "The Director of Reclamation of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. holds a vital role in reconnecting with former members and encouraging their active involvement in the chapter. This role involves reaching out to inactive members, fostering relationships, and promoting the benefits of continued engagement with the fraternity.",
    }, 
    
    
    {
        name: "Jeff Montinard",
        id:8,
        headshots: JeffOfficerCard ,
        position: "Reporter & Social Media Chairman",
        officerInfo: "The Reporter and Social Media Chairman of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. plays a vital role in enhancing the chapter's public presence and communication efforts. This role involves creating engaging and informative content for various digital platforms, including social media channels, websites, and newsletters.",
    }, 
    {
        name: "Xavier Carty",
        id:9,
        headshots: XavierOfficerCard ,
        position: "Kappa League Committee Chairman",
        officerInfo: "The Kappa League Committee Chairman of the Springfield Alumni Chapter of Kappa Alpha Psi Fraternity Inc. leads and oversees the implementation of the Kappa League program. This role involves coordinating mentoring and leadership development activities for young men in the community. ",
    }, 
    
  
    // {
    //     name: "Joe Smith",
    //     id:9,
    //     headshots: "photos will be ref here" ,
    //     position: "Grand Board  Mem 2",
    //     officerInfo: "Lorem Ipsum  is Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum Ipsum",
    // },
    // {
    //     name: "Joe Smith",
    //     id:10,
    //     headshots: "photos will be ref here" ,
    //     position: "Grand Board  Mem 3",
    //     officerInfo: "Lorem Ipsum  is Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum, Lorem Ipsum Ipsum",
    // },

]


 export const isHome = (name) => {
    let lowerCaseName = name.toLowerCase();
    if(lowerCaseName === "home") return "/"
      return  `/${lowerCaseName}`
  }

export  const usefulLinks = [
        {
            id: 1,
            name: "Why Kappa",
            route: 'whyKappa'

        }, 
        {
            id: 2,
            name: "Chapter History",
            route: 'history'

        },
        {
            id: 3,
            name: "Our Impact",
            route: 'impact'

        },


]

export const calendarEvents = [
    {
      title: "The white day party",
      info: "The Springfield Alumni Chapter of Kappa Alpha Psi Fraternity, Inc. welcomes you to join us as we celebrate our 34th Kappaversary  with an illustrious White Party  Celebration. We, the brothers  rise to bring you a  shining bright in White Day  Affair at The White Lion's Garden 1550 Main St. located in the heart of Downtown Springfield MA.",
      date: "2023-06-24",
      dateStr:  'June 24, 2023',
      timeStr:  '1pm - 5pm',
      image: WhitePartyFlyer,
      linkUrl: "https://spfldnupes.ticketspice.com/kappa-white-party"
    },
  
  ]




