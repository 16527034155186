import React, {useEffect,useState} from 'react'
import { Blurhash } from 'react-blurhash'
const ImgBlurAbout2 = ({src,srcSet,alt, isMobile2}) => {
    const [imageLoaded, setImageLoaded] = useState(false)


    useEffect(()=>{
        const img  = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src
    },[src] )
  return (
      <>

        <div style ={{display: imageLoaded ? 'none' : 'inline'}}>
          <Blurhash
          hash="LEGHFrxZ0hV_};R*NHM}03NH%0oK"
          width={ '90%'}
          height={ 'auto'}
          padding={0}
          margin={'1.563rem'}
          resolutionX={32}
          resolutionY={32}
          punch={1}/>
          </div>
    <img 
    src={src}
    alt={alt}
    srcSet={srcSet}
    style ={{display: !imageLoaded ? 'none' : 'inline',
    width:'90%',
    padding:'0', margin:"1.563rem", 
    boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
    


}}
    />
    </>
  )
}

export default ImgBlurAbout2