import React from 'react'
import { Box, Typography, Grid} from '@mui/material'
// import CarouselHero from "../Components/Carousel"
import Carousel2 from '../Components/Carousel2'
import SectionHeader from '../Components/SectionHeader'
import FlexBetween from '../Components/FlexBetween'
import J5Banner from "../assets/images/Jan5banner.jpeg"
import BackToTopBtn from '../Components/BackToTopBtn'
import { Link } from "react-router-dom";

const Home = ({isMobile2}) => {
  return (   
    <Box
    sx={{
      marginTop: "70px",
     backgroundColor:'#FFF', 
      position: "relative"
  }}
    >


    <Carousel2 />
     {/* <CarouselHero isMobile={isMobile} isLg={isLg} isMedLg={isMedLg} isMedSm={isMedSm} /> */}
     
      <SectionHeader header ={'Welcome'} />
      <Grid container
        direction= "column"
        justifyContent="space-between"
        alignItems="center"
      >




      <FlexBetween>
      <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: isMobile2 ? "0 1.5rem": "0 3rem",
          marginTop: "1rem"
        }}
      
      
      > 
 To the  <span className='emphasized-Text'><strong>Springfield Alumni Chapter </strong></span>  of <span className='emphasized-Text'>
           <strong>Kappa Alpha Psi </strong></span>  ! We are a fraternity of  <span className='emphasized-Text'><strong>achievement  </strong></span> and service, committed to upholding the values of our founders and making a positive impact in our community. Our chapter is comprised of a diverse group of men who have dedicated themselves to  <span className='emphasized-Text'><strong> promoting education, leadership, and social responsibility</strong></span>.

As an alumni chapter, we are proud to have a rich history of leadership and service in Springfield, Ma and the surrounding areas. Our members are leaders in their professions, businesses, and community organizations, and we strive to make a difference in the lives of those around us. We believe in fostering brotherhood, encouraging academic excellence, and serving our community through various programs and initiatives.

Whether you are a recent graduate or a seasoned professional, we welcome you to join us in our mission to promote achievement and service. We invite you to explore our website to learn more about our chapter and the programs we offer, and to connect with us to find out how you can get involved. Thank you for stopping by the <span className='emphasized-Text'><strong>Springfield Alumni Chapter of Kappa Alpha Psi </strong></span> page, and click on <span className='emphasized-Text'><strong>the Dreamer </strong></span> diamonds below for more useful information.

      </Typography>
      </FlexBetween>
      {/* // use grid  */} 
    <Grid container
      className='dreamer-container'
      justifyContent={'space-between'}
      alignItems={"center"}
      direction={'row'}
      width = '100%'
      
      margin={'3.5rem 0'}
      p={2.75}
    >  
    <div className="imgBox">
        <img className=' flip img-frontside' src= {require('../assets/images/DiggsCard.png')} alt='Diggs Card' />
        <div className=' flip img-backside'>
        <Link to="/whyKappa" className='diggs-Card-Link' >Why Kappa</Link>
        </div>
    </div>
   
    <div className="imgBox">
        <img className=' flip img-frontside' src= {require('../assets/images/DiggsCard.png')}  alt='Diggs Card' />
        <div className=' flip img-backside'>
        <Link to="/history" className='diggs-Card-Link' > History</Link>

        </div>
    </div>
    <div className="imgBox">
        <img className=' flip img-frontside' src= {require('../assets/images/DiggsCard.png')} alt='Diggs Card' />
        <div className=' flip img-backside'>
        <Link to="/impact" className='diggs-Card-Link' >
              Impact
            </Link>

        </div>
    </div>
    
    </Grid>
    <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    sx={{ 
      width: "100%",
      height: "450px",
      background: `linear-gradient(to bottom, rgba(116, 6, 12, 0.92), rgba(243, 236, 216, 0.83)), url(${J5Banner})`,
      backgroundPosition: "center center",
      loading:'lazy',
      backgroundSize: "cover",
      backgroundRepeat: 'no-repeat',
    }}>  
       
 <Typography  
 variant='h2'
 sx={{
   fontfamily:'Rubik, sans-serif',
   textAlign: 'center',
   fontWeight:'bold',
   color:'#74060b',
   textShadow: '#f2ebd7 1px 0 5px',
   padding: ' 0 5px',
   fontSize: {
          xs: '10vw',
          md:'60px'
   }
 }}
 >
          Celebrating 112 Years Of Achievement 
        </Typography> 
    </Box>
    
    </Grid>
    <BackToTopBtn />
    </Box>
  )
}

export default Home