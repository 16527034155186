import React, {useEffect,useState} from 'react'
import { Blurhash } from 'react-blurhash'
const ImgBlurImpact = ({src, isLarge, isMobile,srcSet,alt}) => {
    const [imageLoaded, setImageLoaded] = useState(false)


    useEffect(()=>{
        const img  = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src
    },[src] )
  return (
      <>
     

        <div style ={{display: imageLoaded ? 'none' : 'inline'}}>
          <Blurhash
          hash="LVQS;%of_Nj@nij[Rkay?bfQMxfQ"
          width={ isMobile ? '250px':'450px'}
          height={ 'auto'}
          resolutionX={32}
          resolutionY={32}
          punch={1}/>
          </div>
    <img 
    src={src}
    alt={alt}
    srcSet={srcSet}
    style ={{display: !imageLoaded ? 'none' : 'inline',
    width: '100%', height: '100%',
    boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)'
    


}}
    />
    </>
  )
}

export default ImgBlurImpact