// import React from 'react'
import { useEffect, useState } from "react"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {IconButton} from '@mui/material'

const BackToTopBtn = () => {
const [back2TopBtn, setBack2TopBtn] = useState(false)

useEffect(() => {
window.addEventListener('scroll', ()=>{
  if(window.scrollY > 100)setBack2TopBtn(true)
  else  setBack2TopBtn(false)
})

}, [])
const scrollUp = () =>{
  window.scrollTo({
    top: 50,
    behavior: 'smooth',
  })
}
  return (
    <div>
        {back2TopBtn && (
        <IconButton
        onClick={scrollUp}
        style={{
          position: 'fixed',
          bottom: '50px',
          right : '20px',
          height:'30px',
          width:'30px',
          border:'1px solid #fff  ',
          borderRadius:0,
          cursor: 'pointer',
          backgroundColor:' #74060c99',
          transform:'rotate(45deg)',
         }}
        >  <KeyboardArrowUpIcon
        sx={{ color: '#fff', transform:'rotate(-45deg)' }}
        /></IconButton>
      )}
    </div>
  )
}

export default BackToTopBtn