import React from 'react'
import { Box, Typography, Grid, Stack} from '@mui/material'

import SectionHeader from '../Components/SectionHeader'
import Springfield from '../assets/images/Springfield.jpg'
import BackToTopBtn from '../Components/BackToTopBtn'
import ImgBlurImpact from '../Components/ImgBlurImpact'
const Impact = ({isMobile, isLarge}) => {
  return (
    <Box
    sx={{
     marginTop: "90px",
     backgroundColor:'white', 
     height:"100%",
     paddingTop: "50px",
  }}
    >
       <SectionHeader header ={'Impact'} />

     < Grid container
        direction= "column"
        justifyContent="space-around" // just this the flex box goes to column  
        alignItems="center"
        sx={{
          paddingBottom:'5.125rem',
      }}
      >
        <Box
        sx={{
          marginTop:'30px',
          width:'100%',
        }}>

          <ImgBlurImpact  src ={Springfield} alt="A overview shot of Springfield" />
          {/* <img
           style={{
             width:'100%',
             boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)'
           }}
          src ={Springfield} alt="A overview shot of Springfield"/> */}
        </Box>
  
      <Stack
       flexDirection= {isLarge ? 'column': 'row'}
      >
      <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          marginTop: "1rem"
        }}> 
The  <span className='emphasized-Text'><strong>
           Springfield Alumni</strong></span> Chapter of <span className='emphasized-Text'><strong>
           Kappa Alpha Psi </strong></span> has had a significant impact on the city of Springfield, Massachusetts, through their community service and outreach programs. The fraternity has been actively involved in various initiatives that promote education, mentorship, and leadership development among young people. They have organized workshops, seminars, and tutoring programs to support local schools and students. Additionally, the chapter has <span className='emphasized-Text'><strong>sponsored scholarship programs
           </strong></span> for deserving high school seniors and has awarded thousands of dollars in financial aid to students pursuing higher education.
      </Typography>

      <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          marginTop: "1rem"
        }}
      > 
    Furthermore, the <span className='emphasized-Text'><strong>
           Springfield Alumni</strong></span> Chapter of <span className='emphasized-Text'><strong>
           Kappa Alpha Psi </strong></span>  has also been involved in various civic engagement initiatives aimed at improving the quality of life for residents in the city. The fraternity has partnered with other organizations to organize community events, food drives, and health fairs. They have also been involved in advocacy efforts aimed at <span className='emphasized-Text'><strong>
           promoting social justice, equality, and fairness in the community</strong></span>. Through their commitment to service, the Springfield Alumni Chapter of Kappa Alpha Psi has made a positive impact on the lives of many in the city of Springfield.
      </Typography>

      <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          marginTop: "1rem"
        }}
      
      
      > 
  In addition to their community service and outreach programs, the <span className='emphasized-Text'><strong>
           Springfield Alumni</strong></span> Chapter of <span className='emphasized-Text'><strong>
           Kappa Alpha Psi </strong></span>  has also played a crucial role in promoting cultural awareness and diversity in the city. The fraternity has organized <span className='emphasized-Text'> <strong>cultural events that celebrate the contributions of African Americans to the arts, music, and literature
           </strong></span>. They have also supported initiatives that promote diversity and inclusion in the workplace, education, and other areas of society. Through their efforts, the chapter has helped to bridge cultural divides and foster a greater sense of unity and understanding in the community. The impact of the  <span className='emphasized-Text'><strong>
           Springfield Alumni</strong></span> Chapter of <span className='emphasized-Text'><strong>
           Kappa Alpha Psi </strong></span>  can be felt throughout the city, and their commitment to service continues to inspire and empower others to make a positive difference in their communities.
      </Typography>
      </Stack>
      </Grid>
      <BackToTopBtn />
    </Box>
  )
}

export default Impact