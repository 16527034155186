import React from 'react'
import { Box, Grid,Stack} from '@mui/material'
import SectionHeader from '../Components/SectionHeader'
import Officer from '../Components/Officer'
import HorizontalScrollbar from '../Components/HorizonatalScrollBar'
import BackToTopBtn from '../Components/BackToTopBtn'
import kappaCrest from '../assets/images/kappaCrest.png'
import { officerPositionsCardInfo } from '../utils/resuablesJS'
const Officers = ({isMobile}) => {
  return (

    <Box
    sx={{
     marginTop: "90px",
     backgroundColor:'white', 
     height:"100%",
     paddingTop: "50px", 
  }}>
       <SectionHeader header ={'Officers'} />

     < Grid container
        direction= "column"
        justifyContent="space-around" // just this the flex box goes to column  
        alignItems="center"
        sx={{
          paddingBottom:'5.125rem',
      }}
      >
           {isMobile ? (
      <Stack 
      sx={{ display: {  md: "none" }}}
         style={{
          border: '1px solid black',
          margin:'30px auto',  // work on making the float in page
          width: '100%',
          backgroundImage:`linear-gradient(to bottom, rgba(116, 6, 12, 0.95), rgba(116, 6, 12, 0.95)), url(${kappaCrest})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition:" 50% 50%",
          padding: '20px 0'
      }} >
     
         
       <HorizontalScrollbar data ={officerPositionsCardInfo} />
         </Stack>     
      ):(
      <Grid
       container 
      height={'100%'}
      justifyContent='space-evenly'
       alignItems='center'
       sx={{  
         display: { xs: "none", md: "flex" },
         margin: '50px auto 0 auto',
         backgroundImage:`linear-gradient(to bottom, rgba(116, 6, 12, 0.92), rgba(116, 6, 12, 0.93)), url(${kappaCrest})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition:" 50% 50%",
          padding: '130px 0',
          boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
      
      }}
      >
       {officerPositionsCardInfo.map((officer) =>(
            <Grid
            key={officer.id}
            item>
                <Officer 
                  position={officer.position} 
                  index = {officer.id }
                  name={officer.name }
                  officerInfo={officer.officerInfo}
                  officerHeadshot={officer.headshots}
                  />
             </Grid>
             ))}
      </Grid>
      )}

    </Grid>
    <BackToTopBtn />
    </Box>
 

  )
}

export default Officers