import React from 'react'
import {Typography,Stack,Box} from '@mui/material'
// import {styled} from "@mui/system" 
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import TikTokIcon from './TikTokIcon';
// import CashAppIcon from './CashAppIcon';
import {Link} from'react-router-dom';
import { usefulLinks } from "../utils/resuablesJS";


const Footer = ({isMobile,isSmall}) => {
  return (
    <Box
    flex={1}
    pt={4}
    pb={4}
    sx={{ 
     backgroundColor: "#74060b",
        color: '#F2EBD7',
    }}>
    <Stack 
    spacing={4}
    gap= {isSmall ? 0 : 3}
    direction={{ xs: "colum", sm: "row" }}
     p={isSmall ? 4 : 1}
     >
      <Box flex={1}  
       
      sx={{ border: '1px solid #F2EBD7', padding: '1rem',
     
     }}
      >
        <Typography color={"white"} align={"left"}>
         
        </Typography>
        <Typography 
          
            mb={1}
          >
            Contact Us:
          </Typography>
        <Typography  
        align={"left"}
       
        >
           <a href='tel:+4134594586' className='footer-link'>
           413-459-4586 
           </a>
        </Typography>
       
        <Typography  
        align={"left"}
        >
           <a href='mailto:springfieldmanupes@gmail.com'  className='footer-link'>Springfieldmanupes@&#8203;gmail.com</a>

        </Typography>
        <Typography  
        align={"left"}
        >
        <a href= 'https://www.kapsinep.org/index.php/membership/prospective-members' target='_blank' className=' footer-link footer-useful-link' rel="noreferrer">
          Member Intake
          </a> 

        </Typography>
       
      </Box>



       <Stack
          flex={1}
        direction={'row'}
        justifyContent={'space-evenly'}
      sx={{
         border: '1px solid #F2EBD7', padding: '1rem 0',
         height: '62px',
        alignSelf: {xs:'none', sm:'center'}

     
    }}
       >
       


          <a href='https://www.facebook.com/profile.php?id=100095052697370' target='_blank'  className='footer-link-icon facebook-link' rel="noreferrer" >
            <FacebookIcon />
              </a>

          <a href='https://www.instagram.com/springfieldaluminupes/' target='_blank'  className='footer-link-icon instagram-link' rel="noreferrer" >
            <InstagramIcon />
              </a>

              {/* <a href='https://twitter.com/' target='_blank' className='footer-link-icon twitter-link' rel="noreferrer"  >
              <TwitterIcon />
              </a>   
              <a href='https://tiktok.com/' target='_blank' className='footer-link-icon tiktoc-link' rel="noreferrer"  >
              <TikTokIcon color='#F2EBD7' />
              </a>
              <a href='https://cash.app/' target='_blank' className='footer-link-icon cashapp-link' rel="noreferrer"  >
              <CashAppIcon color='#F2EBD7' />
              </a> */}


         
        </Stack> 

      <Box flex={1}
            sx={{ border: '1px solid #F2EBD7', padding: '1rem',
            display:'flex', flexDirection:'column'
          }}
      >
            <Typography 
            // variant='h6'
            //  fontSize='0.938rem'
            
            mb={1}
             >
            Useful Links:
          </Typography>
          {usefulLinks.map((link) => (
            
                <Link key={link.id} to={link.route} className='footer-useful-link' >
                  <Typography>
                {link.name}
                  </Typography>
              </Link>
          ))}
      </Box>
       </Stack>
       <Typography wariant="h6"
         fontSize='0.8rem'
         mt={2}
         textAlign="center"
      >
        {`${new Date().getFullYear()} | VintageCodes © | SA NUPES`}
       
      </Typography>
  </Box>

  )
}

export default Footer
