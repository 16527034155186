import React from 'react'
import { Box, Typography,Grid, ImageList,ImageListItem} from '@mui/material'

import SectionHeader from '../Components/SectionHeader'
import FlexBetween from '../Components/FlexBetween'
import { imageInfoData } from '../utils/imageListData'
import BackToTopBtn from '../Components/BackToTopBtn'
import ImgBlurWhy from '../Components/ImgBlur-Why'
const Why = ({isMobile}) => {
  return (
    <Box
    sx={{
     marginTop: "90px",
     backgroundColor:'white', 
     height:"100%",
     paddingTop: "50px"
  
  }}
    >
   <SectionHeader header ={'Why Kappa'} />

   < Grid container
        direction= "column"
        justifyContent="space-between"
        alignItems="center"
        // spacing={2}
        sx={{
          paddingBottom:'5.125rem',
      }}
      >

      <Box
       width='100%'
      >

<ImageList 
variant={'quilted'}
sx={{ 
  width: {
      lg:'70%',
      xs:'99%'

}, 
 margin:{
   xs:'1.25rem auto',
   lg:'2.5rem'
 },
height: 460, float:{
        lg: 'left',
        xs: 'none'
},
}} 

cols={isMobile ? 1 : 2} 
>
      {imageInfoData.map((img) => (
        <ImageListItem key={img}>
          <ImgBlurWhy 
          src={`${img}?w=164&h=164&fit=crop&auto=format`}
          srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
          alt={img}
          />
          {/* <img
            src={`${img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={img}
            loading="lazy"

            // style={{
            //   width: '100%',
            // }}
          /> */}

        </ImageListItem>
      ))}
    </ImageList> 

      <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          marginTop: "3rem"
        }}>
            <span className='emphasized-Text'>
      <strong> Kappa Alpha Psi Fraternity </strong></span> , Incorporated was founded in Bloomington, Indiana on the campus of Indiana University at Bloomington. Their  <span className='emphasized-Text'>
      <strong>ten founders </strong></span> sought companionship at the University due to the racial tensions that they faced while attending the school.  The racial prejudice and discrimination encountered by the <span className='emphasized-Text'>
      <strong>founders </strong></span>  strengthened their bond of friendship and growing interest in starting a social group. These 10 bright founders went on to become entrepreneurs, lawyers, doctors, and scientists, something that was unthinkable at the height of racism in America. 


      </Typography>
      </Box>
      <FlexBetween>
      <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          marginTop: "3rem"
        }}>
        Today that bond is tighter than ever, a bond so special that many prestigious and distinguished men have gravitated towards it. Every brother who has donned the letters of<span className='emphasized-Text'>
      <strong> 	&Kappa; &Alpha; &#968;  </strong></span>  
       have had their own reasons to join but, each  has had that burning desire inside of them. The desire <span className='emphasized-Text'>
      <strong>to Achieve in every field of human endeavor</strong></span>. There is something truly special about a Kappa man, and many have acted as a role model to both men and women.  In nature, diamonds are formed under pressure and heat causing carbon atoms to crystallize and form coveted diamonds and that is exactly what the Kappa man is.


      </Typography>

      </FlexBetween>
      </Grid>
     

      <BackToTopBtn />
    </Box>
  )
}

export default Why