import React from 'react'
import { Box, Typography, Grid,} from '@mui/material'
import SectionHeader from '../Components/SectionHeader'
import BackToTopBtn from '../Components/BackToTopBtn'
import SaCalendar from '../Components/SaCalendar'

const Events = ({title, isMobile}) => {
  return (
    <Box
    sx={{
     marginTop: "90px",
     backgroundColor:'white', 
     height:"100%",
     paddingTop: "50px", 
  }}>
       <SectionHeader header ={'Events'} />

     < Grid container
        direction= "column"
        justifyContent="space-around" // just this the flex box goes to column  
        alignItems="center"
        sx={{
          paddingBottom:'5.125rem',
      }}
      >
         <Typography variant='body1'
        sx={{
          fontSize: "1.2rem",
          lineHeight: "2.75rem",
          padding: "0 3rem",
          margin: "1rem 0"
        }}> 
        Come support the  <span className='emphasized-Text'><strong>
           Springfield Alumni Nupes</strong></span>  at our events, check the calander out below for all of our events. Look foward to seeing you there. <span className='emphasized-Text'><strong>
           YO!</strong></span>
      </Typography>
      <SaCalendar isMobile={isMobile} />

      </Grid>
      <BackToTopBtn />
    </Box>
  )
}

export default Events