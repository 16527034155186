import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {useTheme,useMediaQuery,Container} from '@mui/material'
import Navbar from "./Components/Navbar"
import Footer from "./Components/Footer";
import Home from "./scenes/Home"
import About from "./scenes/About"
import Contact from "./scenes/Contact"
import History from "./scenes/History"
import Officers from "./scenes/Officers"
import Events from "./scenes/Events"
import Service from "./scenes/Service"
import Why from "./scenes/Why"
import Impact from "./scenes/Impact"
import ScrollToTop from "./Components/ScrollToTop";



function App() {
  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile2 = useMediaQuery(theme.breakpoints.down("sm"));
    const isSmall = useMediaQuery(theme.breakpoints.between("xs, sm"));
    // const isMedSm = useMediaQuery(theme.breakpoints.between("sm,md"));
    // const screenToLarge = useMediaQuery(theme.breakpoints.between("xs,lg"));
    // const isMedLg = useMediaQuery(theme.breakpoints.between("md,lg"));
    // const isLg = useMediaQuery(theme.breakpoints.between("lg,xl"));

    const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
    


  return (

        <Container  
        className="app"
        
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft:{
            xs: '0',
            md:'16px'
          },
          paddingRight:{
            xs: '0',
            md:'16px'
          },

          width: {
            xs: '100%',
            md: '80%'
          }
        }}
        
        
        >
       <Router>
         <ScrollToTop />
      <Navbar />
      <Routes>
     
        <Route exact path="/" element ={<Home isMobile2={isMobile2}  />} />
        <Route path="/about" element={<About isMobile2={isMobile2} />} />
        <Route path="/officers" element={<Officers isMobile={isMobile} />} />
        <Route path="/service" element={<Service 
        isMobile2={isMobile2} />} />
        <Route path="/events" element={<Events isMobile={isMobile2} />} />
        <Route path="/contact" element={<Contact isMobile={isMobile} />} />
        <Route path="/history" element={<History isMobile={isMobile2} isLarge={isLarge} />} />
        <Route path="/impact" element={<Impact isMobile={isMobile} isLarge={isLarge} />} />
        <Route path="/whyKappa" element={<Why isMobile={isMobile2} />} />

      </Routes>
      <Footer  isSmall={isSmall}/>
     
    </Router>
  </Container>

  );
}

export default App;
