import React, { useState } from "react";


import FullCalendar from "@fullcalendar/react";
import dayListPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
 import { calendarEvents } from "../utils/resuablesJS";
 import { Box, Typography, Modal, IconButton} from '@mui/material'
 import CloseIcon from '@mui/icons-material/Close';

const SaCalendar = ({title, isMobile}) => {
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({
    title: "",
    start: new Date(),
    extendedProps: {}
  });
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? "100%" : 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    border: '2px solid #74060b',
    boxShadow: 24,
    p: 4,
  };
  const toggle = () => setOpen(!open);
  const handleEventClick = ({ event, el }) => {
    toggle();
    setEvent(event); // calling an  obj
  };

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    return (
      <div 
      style={{ 
        width: "90%",
        padding: "10px",
        boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
        border: "2px solid #74060b" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* add in close icon  */}
        <Box sx={style}>
        <IconButton onClick={() => handleClose()}   
        sx={{backgroundColor:"#f2ebd7", borderRadius:"0", position:'fixed', 
        width:  '35px',
        height: '35px', 
        top: isMobile ? '3.5%' :'30px',
        left:isMobile ? '82%' :'325px',
        // top:{sm:"2%", xs: "3.5%"}, left:{sm:"92%", xs: "82%"}, 
        transform:" rotate(45deg) ",  "&:hover":{backgroundColor:"#f2ebd7"} }}>
        <CloseIcon sx={{color: "#74060b", transform:" rotate(45deg) "}} />
      </IconButton>

        <a className="event-link" href={event.extendedProps.linkUrl} target="_blank" rel="noreferrer">
          <Typography id="modal-modal-description" variant="h6" component="h2" sx={{  textTransform:'capitalize', 
          }}>
          {event.title}
          </Typography>
          </a>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color:'#74060b'}}>
          {`Date: ${event.extendedProps.dateStr}`}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color:'#74060b', mb: 2,}}>
          {`Time: ${event.extendedProps.timeStr}`}
          </Typography>

          <Box
            sx={{
              width:'90%',
              maxHeight: '400px'
            }} >

          <img
          style={{
            width: '100%',
            loading:'lazyLoading', 
            boxShadow: '0px 5px 5px 0px rgba(116,6,11,0.61)',
          }}
          src={event.extendedProps.image} alt="event flyer" />
          </Box>
           

        </Box>
      </Modal>


      <FullCalendar
      
        header={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth, listWeek"
        }}
        selectable={true}
        plugins={[interaction, dayListPlugin, dayGridPlugin, bootstrapPlugin]}
        themeSystem="bootstrap5"
        weekends={true}
        displayEventTime={true}
        timeZone="UTC"
        events={calendarEvents}
        eventClick={handleEventClick}
        eventColor={'#74060b'}
        eventColorText={'#F2EBD7'}
      />

      </div>
    )
  }
  
  export default SaCalendar